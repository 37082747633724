import React from 'react';
import inBrowser from 'src/utils/inBrowser';
import isLoggedIn from 'src/utils/isLoggedIn';
import Login from 'src/components/auth/Login';
import PropTypes from 'prop-types';

const LoginPage = ({options}) => {
    const redirect = '/account';
    const popupOptions = options.registerPopup || null;
    const termsOptions = options.termsAndConditions || null;

    if (inBrowser && isLoggedIn) {
        window.location.href = '/account';
    }

    return (
        <Login registerPopupOptions={popupOptions} redirect={redirect} termsOptions={termsOptions} />
    );
};

export default LoginPage;

LoginPage.propTypes = {
    options: PropTypes.shape({
        registerPopup: PropTypes.object,
        termsAndConditions: PropTypes.object,
    }),
};

LoginPage.defaultProps = {
    options: {
        registerPopup: {},
        termsAndConditions: {},
    },
};
